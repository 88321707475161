<template>
  <stemble-card v-if="isTaskReady" width="100%">
    <div v-if="isSuperAdmin" class="edit-button-wrapper">
      <a :href="editTaskUrl">
        <v-icon size="1.125rem" color="#2C8FCC">mdi-pencil</v-icon>
        Edit Task</a
      >
    </div>
    <v-row no-gutters>
      <v-col>
        <div v-if="loading">
          <stemble-loader />
        </div>
        <component
          :is="taskType"
          v-else-if="isStateReady"
          ref="subCard"
          v-bind="componentAttrs"
          @update:inputData="
            inputData = $event;
            debouncedStoreResponse();
            $emit('update:inputData', $event);
          "
          @update:taskAttachments="
            taskAttachments = $event;
            debouncedStoreResponse();
            $emit('update:taskAttachments', $event);
          "
          @submit-response="submitResponse"
          @change-selected-response="$emit('change-selected-response', $event)"
        />
        <div v-else-if="task.isRandomizable()">
          <v-alert>
            This task isn't loading properly right now. Please try refreshing the page. If the
            problem persists, let us know at
            <a href="mailto:support@stemble.com">support@stemble.com</a>.
          </v-alert>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="fileUploadSavedSnackbarOpen" color="success">
      {{ $t('fileUploads.uploadsSaved') }}
    </v-snackbar>
  </stemble-card>
</template>

<script>
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import CalculationQuestion from './CalculationQuestion';
import DynamicQuestion from './DynamicQuestion';
import DataDrivenTask from './DataDrivenTask';
import WatchVideoTask from './WatchVideoTask';
import TaskAssignment from '../models/TaskAssignment';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import StembleLoader from '@/loading/components/StembleLoader';
import TaskResponse from '@/tasks/models/TaskResponse';
import Grade from '@/grades/models/Grade';
import User from '@/users/models/User';
import TaskState from '../../task-states/models/TaskState';
import Assignment from '@/assignments/models/Assignment';
import Task from '@/tasks/models/Task';
import IssuedTaskState from '../../task-states/models/IssuedTaskState';
import StembleCard from '@/common/components/StembleCard.vue';
import {ErrorCode} from '@/common/api/ErrorCode';
import {inject} from '@/container';
import {RefinerService} from '@/onboarding/services/RefinerService';
import {MARKING_TASKS} from '@/router/route-names';
import debounce from 'lodash.debounce';

export default {
  name: 'TaskCard',
  components: {
    StembleCard,
    StembleLoader,
    WatchVideoTask,
    DataDrivenTask,
    DynamicQuestion,
    CalculationQuestion,
    MultipleChoiceQuestion,
  },
  props: {
    assignment: {
      type: Assignment,
      required: false,
      default: null,
    },
    task: {
      type: Task,
      required: true,
    },
    taskAssignment: {
      type: TaskAssignment,
      required: false,
      default: null,
    },
    index: {
      required: false,
      type: Number,
      default: 0,
    },
    isPastDue: {
      type: Boolean,
      required: false,
      default: false,
    },
    tryIt: {
      type: Boolean,
      required: false,
      default: false,
    },
    responses: {
      type: Array,
      required: true,
    },
    response: {
      type: TaskResponse,
      required: false,
      default: null,
    },
    grade: {
      type: Grade,
      required: false,
      default: null,
    },
    feedbackByPart: {
      type: Object,
      required: false,
      default: null,
    },
    taskState: {
      type: TaskState,
      required: false,
      default: null,
    },
    selectedResponseIndex: {
      type: Number,
      required: false,
      default: 0,
    },
    responseAttachments: {
      type: Array,
      default: () => [],
    },
    user: {
      type: User,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    autoSaveEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isTaskSaved: false,
      tryItData: {
        grades: [],
        responses: [],
      },
      tryItSelectedResponseIndex: 0,
      taskAttachments: [],
      inputData: {},
      fileUploadSavedSnackbarOpen: false,
    };
  },
  computed: {
    componentAttrs() {
      const {
        task,
        taskAssignment,
        assignment,
        isSubmitting,
        responses,
        selectedResponseIndex,
        response,
        grade,
        taskState,
        feedbackByPart,
      } = this;
      return {
        task,
        taskAssignment,
        assignment,
        isSubmitting,
        // FIXME: this will not be the same generally
        allowEditing: !isSubmitting,
        responses,
        selectedResponseIndex,
        response,
        grade,
        taskState,
        feedbackByPart,
        isPastDue: this.isPastDue,
      };
    },
    betaUiUrl() {
      return `/courses/${this.assignment.courseId}/assignments/${this.assignment.id}/marking/${this.user.id}/tasks/${this.task.id}`;
    },
    editTaskUrl() {
      return `/tasks/${this.task.id}/edit`;
    },
    isTaskReady() {
      return this.task !== null;
    },
    isStateReady() {
      return (this.isTaskReady && !this.task.isRandomizable()) || this.taskState !== null;
    },
    taskContent() {
      return this.task.taskContent || null;
    },
    taskType() {
      return this.task.taskType;
    },
    isMarking() {
      return this.$route.name === MARKING_TASKS;
    },
    isSubmitting() {
      return this.$loadingFlags.isLoading(LoadingFlag.AssignmentSubmittingResponse);
    },
    isSuperAdmin() {
      return this.$gate.can('view', 'SuperAdmin');
    },
    debouncedStoreResponse() {
      return this.autoSaveEnabled && !this.tryIt
        ? debounce(this.storeResponse.bind(this), 1500)
        : debounce(() => {}, 1500);
    },
  },
  watch: {
    response(newResponse) {
      if (newResponse !== null) {
        this.setResponse(newResponse);
        this.loadTaskState();
      } else {
        this.clearResponse();
      }
    },
  },
  created() {
    this.loadTaskState();
    const refinerService = inject(RefinerService);
    refinerService.addToResponse({
      questionId: this.task.content.questionId,
      component: this.task.content.component,
    });
  },
  methods: {
    isFaculty() {
      return this.$auth.user.isFaculty();
    },
    selectLatestResponse() {
      this.$emit('select-latest-response');
    },
    setResponse(response) {
      if (this.$refs.subCard) {
        this.$refs.subCard.setResponse(response);
      }
    },
    clearResponse() {
      if (this.$refs.subCard) {
        this.$refs.subCard.clearResponse();
      }
    },
    triggerSubmitResponse() {
      this.$refs.subCard.submitResponse();
    },
    storeResponse() {
      const response = this.inputData;

      this.$loadingFlags
        .loadingHandler(
          LoadingFlag.AssignmentStoringResponse,
          TaskResponse.api.storeResponse(
            this.user.id,
            this.assignment.id,
            this.task.id,
            response,
            {taskStateId: this.taskState ? this.taskState.id : null},
            [].concat(...[this.taskAttachments, this.responseAttachments].filter(Array.isArray))
          )
        )
        .then(async () => {
          if (this.taskAttachments.length || this.responseAttachments.length) {
            this.fileUploadSavedSnackbarOpen = true;
          }
          this.taskAttachments = [];
          this.$emit('clear-attachments');
          this.$emit('response-saved');
        })
        .catch(this.catchResponseError.bind(this))
        .then(() => {
          this.selectLatestResponse();
        });
    },
    submitResponse(eventPayload) {
      this.debouncedStoreResponse.cancel();

      if (this.tryIt) {
        return this.submitTryItResponse(eventPayload);
      } else {
        return this.submitAssignmentResponse(eventPayload);
      }
    },
    submitAssignmentResponse({response}) {
      this.$loadingFlags
        .loadingHandler(
          LoadingFlag.AssignmentSubmittingResponse,
          TaskResponse.api.submitResponse(
            this.user.id,
            this.assignment.id,
            this.task.id,
            response,
            {taskStateId: this.taskState ? this.taskState.id : null},
            [].concat(...[this.taskAttachments, this.responseAttachments].filter(Array.isArray))
          )
        )
        .then(async () => {
          this.taskAttachments = [];
          this.$emit('clear-attachments');
          this.$emit('response-submitted');
          return Grade.api
            .loadGradesForTask(this.user.id, this.assignment.id, this.task.id)
            .catch((err) => {
              if (err.response && err.response.status === 403) {
                // Student likely just not allowed to see their grade yet
                return;
              }
              return this.$errorReporting.showErrorDialog(err, {
                text: 'There was an error fetching your grade. Try refreshing the page. If that does not work, please let us know.',
              });
            });
        })
        .catch(this.catchResponseError.bind(this))
        .then(() => {
          this.selectLatestResponse();
        });
    },
    catchResponseError(err) {
      if (err.response && err.response.status === 403) {
        // TODO: this should be more informative based on policies or maybe error codes
        return this.$errorReporting.showErrorDialog(err, {
          text: 'You are not authorized to submit new responses.',
          options: {
            enableSendingLogs: false,
            showEmailSupport: false,
            buttonText: 'Close',
          },
        });
      } else if (err.response && err.response.status === 413) {
        return this.$errorReporting.showErrorDialog(err, {
          title: this.$t('taskCard.submissionTooLargeTitle'),
          text: this.$t('taskCard.submissionTooLarge'),
          options: {
            enableSendingLogs: false,
            showEmailSupport: false,
            buttonText: 'Close',
          },
        });
      } else if (
        err.response &&
        err.response.data &&
        err.response.data.code === ErrorCode.INVALID_CHEMICAL_EQUATION
      ) {
        return this.$errorReporting.showErrorDialog(err, {
          text: 'One or more chemical equations are invalid. Check the equation(s) highlighted in red.',
          options: {
            enableSendingLogs: false,
            showEmailSupport: false,
            buttonText: 'Close',
          },
        });
      } else if (
        err.response &&
        err.response.data &&
        err.response.data.code === ErrorCode.PAST_DUE_SUBMISSION_ATTEMPTED
      ) {
        return this.$errorReporting.showErrorDialog(err, {
          text: err.response.data.message,
          options: {
            enableSendingLogs: false,
            showEmailSupport: false,
            buttonText: 'Close',
          },
        });
      } else {
        return this.$errorReporting.showErrorDialog(err, {
          text: 'There was an error submitting your response. Try again soon, but let us know if the issue persists.',
        });
      }
    },
    submitTryItResponse({response}) {
      this.$loadingFlags
        .loadingHandler(
          LoadingFlag.AssignmentSubmittingResponse,
          TaskResponse.api.tryItResponse(this.task.id, response, {
            taskStateId: this.taskState ? this.taskState.id : null,
          })
        )
        .then((data) => {
          const {grade, response} = data;
          // Attach the grade since this is outside of the ORM
          response.grade = grade;
          this.$emit('add-try-it-response', {grade, response});
        })
        .catch((err) =>
          this.$errorReporting.showErrorDialog(err, {
            text: 'There was an error submitting your response. Please contact us if the issue persists.',
          })
        );
    },

    reloadTask() {
      this.$emit('reload-task');
    },
    forceUpdate() {
      // FIXME: Task state should be passed into the component.  This is a temporary workaround for the marking screen user navigation.
      this.loadTaskState();
    },
    loadTaskState(force = false) {
      // Loads task state if required
      if (this.task.isRandomizable() && (force || this.taskState === null)) {
        if (this.response && this.response.taskStateId) {
          this.$loadingFlags.loadingHandler(
            LoadingFlag.AssignmentTaskState,
            TaskState.api.get(this.response.taskStateId)
          );
        } else if (this.assignment !== null) {
          this.$loadingFlags.loadingHandler(
            LoadingFlag.AssignmentTaskState,
            IssuedTaskState.api.getForTask(this.task.id, {
              user: this.user.id,
              assignment: this.assignment.id,
            })
          );
        }
      }
    },
  },
};
</script>

<style lang="sass">
.edit-button-wrapper
  position: absolute
  top: 0
  right: 0
  overflow: hidden
  border-top-right-radius: 4px
  border-bottom-left-radius: 6px

  a
    text-decoration: none
    display: flex
    align-items: center
    gap: 0.25rem
    overflow: hidden
    box-shadow: inset 0 0 0 1px rgba(31, 59, 81, 0.15), 0px 1px 2px 0px rgba(18, 11, 40, 0.05), 1px 4px 14px 0px rgba(44, 32, 80, 0.06)
    margin: -1px -1px 0 0
    border-bottom-left-radius: 6px
    padding: 5px 12px
    background: linear-gradient(to right, #f5f5f5, #fafafa)
    cursor: pointer
    transition: all ease-out 100ms
    overflow: hidden

    &:hover
      background: white
      color: #2C8FCC

      v-icon
        color: #2C8FCC

    &:active
      background: linear-gradient(to right, #f5f5f5, #f5f5f5)

.beta-pill
  background: linear-gradient(to right, #ffedd5 0%, #fed7aa 100%)
  color: rgb(194 65 12)
  padding: 0.375rem 0.625rem
  border: 1px solid rgb(253 186 116 / 0.7)
  border-radius: 100rem
  font-size: 0.875rem
  font-weight: 600
  display: inline-block
  text-transform: uppercase
  letter-spacing: 0.025em
  line-height: 1

.beta-link
  color: #2B65AC
  text-decoration: none
  font-size: 1.125rem
  font-weight: 600
  display: flex
  align-items: center
  margin-bottom: 1rem
  margin-top: -0.5rem
  gap: 0.5rem
  transition: all 0.15s ease-out

  &:hover
    opacity: 0.7

.submitButton
  background-color: #d83d0e !important
  border: 0px !important

.theme--light.v-label
  color: black !important

.is-selected
  background-color: #B9C9D8 !important
  color: white !important

.is-active-reload
  color: #FFAC6E !important

.text-btn::before
  color: transparent !important

.text-btn:hover
  color: #d83d0e !important

.text-btn
  margin-right: -18px

.no-background-hover::before
  background-color: transparent !important

.v-card__text
  line-height: 28px

.progressContainer
  width: 60%
  margin: 0 auto

.progress
  margin: 0
  border-radius: 5rem
  overflow: hidden
  border: none
  background-color: red !important

@media only screen and (min-width: 768px)
  .quiz_card_height
    height: 100%
  .quiz_button_width
    width: 50%

@media only screen and (max-width: 768px)
  .quiz_card_height
    height: 100%
  .quiz_button_width
    width: 100%
</style>
